@import 'core';

.AppCore {
    .ProductWallDesignerSettingsDialog {
        position: absolute;
        z-index: 10;
        margin-top: 4px;
        width: 516px;

        @include media-breakpoint-down(tablet) {
            left: 25% !important;
        }

        .Row {
            flex-wrap: wrap;
        }

        .VisionStyleToolbar {
            > div {
                > .container {
                    display: inline-block;

                    > .Row {
                        > .Col {
                            padding: 7px;

                            > .ToggleButton .Button > .Icon.m {
                                margin-left: -10px !important;
                                margin-right: -10px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .PlacedProductSettings,
    .ProductWallDesignerSettingsDialog {
        .SmallInputWrap {
            input {
                padding-top: 6px;
                padding-bottom: 6px;
                min-width: 100px;
            }
        }

        .AlignButton {
            > span {
                display: flex;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.FlexCenter {
                    justify-content: center;
                }

                &.FlexEnd {
                    justify-content: flex-end;
                }

                > span {
                    display: block;
                    vertical-align: top;
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    background: $grey-light-1;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &.AlignButtonActive > span > span {
                background: $primary-blue;
            }
        }

        .SliderWrap .InputBaseDescription {
            margin-top: 0;
        }

        .InputBaseLabel {
            margin-bottom: 0;
        }

        .InputBaseWrapper .InputBaseLabel {
            margin-bottom: 5px;
        }

        .CheckboxLabel .InputBaseLabel {
            display: inline;
            line-height: 18px;
        }

        .PlacedProductSettingsImageThumbnail {
            position: relative;
            width: 100%;
            cursor: pointer;

            &:before {
                content: '';
                display: block;
                padding-top: 100%;
            }

            img {
                object-fit: contain;
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                position: absolute;
                top: 2px;
                left: 2px;
                bottom: 2px;
                right: 2px;
            }

            &.Selected {
                border-color: $primary-blue;
            }
        }

        .ProductCodesList {
            > dd + dt {
                margin-top: 5px;
            }
        }
    }

    .HotspotProductSelectLabel {
        display: block;
        width: 100%;
        max-width: 360px;
        overflow: hidden;
        text-overflow: ellipsis;

        img {
            max-width: 40px;
            max-height: 40px;
            margin-right: 14px;
        }
    }
}
