@import 'core';

.AppCore {
    .CustomFrameDragLayer,
    .CustomFrameGraphics {
        text-align: center;

        > a {
            display: inline-block;
            vertical-align: middle;
            width: 62px;
            height: 62px;
            position: relative;
            border-bottom: none;
            cursor: move;

            .Icon,
            > span {
                display: block;
                position: absolute;
            }

            .Icon {
                color: $grey-dark-2;
            }

            .Icon:first-child {
                top: 6px;
                left: 6px;
            }

            > span {
                top: 19px;
                left: 28px;

                width: 1px;
                height: 20px;
                background: $grey-light-1;
                transform: rotate(45deg);
            }

            .Icon:last-child {
                bottom: 6px;
                right: 6px;
            }
        }

        &:hover > a {
            .Icon {
                color: $grey-dark-1;
            }

            > span {
                background: $grey-dark-2;
            }
        }
    }
}
