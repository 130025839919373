@import 'core';

.AppCore {
    .EyedropperTool {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: $depth-above-everything + 10;
        pointer-events: none;

        > canvas {
            background: $white;
            border-radius: 50%;
            outline: 1px solid black;
            transform: translate(calc(-50% + 30px), calc(-50% + 30px));
        }
    }
}
