@import 'core';

.AppCore {
    .InteractiveScreen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 65px 56px;
        background: $white;

        &.AccessibilityMode {
            padding-right: 120px;
        }

        .ScreenTitle {
            color: #061d5f;
            font-size: 31px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 33px;
            text-align: center;
        }

        .SearchForm {
            margin-top: 62px;

            > .InputBaseWrapper {
                > .InputBaseWrapperInner {
                    > .InputBaseWrapperInnerInner > .Input {
                        padding-top: 25px;
                        padding-bottom: 23px;
                        padding-left: 36px;
                        font-size: 29px;
                        line-height: 31px;
                    }

                    > .InputButtonRight {
                        display: flex;

                        .Button {
                            flex: 0;

                            &.plain {
                                border-radius: 0;
                                border-top: 1px solid $grey-light-1;
                                border-bottom: 1px solid $grey-light-1;
                            }

                            &.primary {
                                font-size: 23px;
                                line-height: 28px;
                                color: #061d5f;
                                background-color: #a7dfe7;

                                &:disabled {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }

                    > .Input:focus,
                    > .Input.active {
                        & + .InputButtonRight {
                            .Button.plain {
                                border-color: $grey-dark-2;
                            }
                        }
                    }
                }
            }
        }

        .SearchStatus {
            margin-top: 65px;
            color: #061d5f;
            font-size: 26px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
        }

        .SearchResults {
            margin-top: 60px;
            height: calc(100% - 289px);
            overflow-x: hidden;
            overflow-y: auto;

            &.WithOSK {
                height: calc(100% - 256px - 579px);
            }

            > .Row > .Col {
                width: 25%;
            }
        }

        .SearchResult {
            display: block;
            border: 1px solid #eff6f7;
            border-radius: 3px;
            background-color: $white;
            position: relative;

            > .HiddenLabel {
                position: absolute;
                bottom: -10px;
                right: 5px;
            }
        }

        .SearchResultTitle {
            display: block;
            padding: 11px 16px 13px 17px;
            background-color: #eff6f7;
            color: #061d5f;
            font-size: 23px;
            font-weight: 300;
            line-height: 27px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        .SearchResultImage {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
        }

        &.AccessibilityMode {
            .SearchStatus {
                margin-top: 15px;
                margin-bottom: 45px;
            }

            .SearchResults {
                margin-top: 0;
                height: calc(100% - 289px - 20px);

                > .Row {
                    height: 100%;
                    align-items: flex-end;
                }

                &.WithOSK {
                    height: calc(100% - 256px - 600px);
                }

                & + .SearchStatus {
                    margin-top: -26px;
                    margin-bottom: 0;
                }
            }

            .SearchForm {
                margin-bottom: 60px;

                .OnScreenKeyboard {
                    margin-top: 0;
                    margin-bottom: 62px;
                    width: 90%;
                    margin-left: 0;
                }
            }
        }
    }
}
