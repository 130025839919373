@import 'core';

.AppCore .ProductWallDesigner {
    &.LivePreview,
    &.ProductWallDesignerPreviewMode,
    &.ProductWallDesignerLiveMode {
        position: relative;

        .TouchTargets {
            transform-origin: top left;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;

            .Body {
                position: relative;
            }

            .BodyArea {
                position: absolute;
                width: 100%;
            }

            .PlacedProduct {
                position: absolute;
                bottom: 0;
                z-index: 5;
                cursor: pointer;
                border-bottom: none;
            }
        }
    }
}
