@import 'core';
@import './ShelfStyle2D';
@import './ShelfStyle3D';

.AppCore .ProductWallDesigner {
    .Canvas {
        height: 100%;

        &.OnTopOfPreviewImage {
            position: relative;
        }

        .Body {
            position: relative;

            > video {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }

        .BodyArea {
            position: absolute;
            width: 100%;

            &.LastBodyArea {
                color: inherit;
            }
        }

        .ProductsShelf,
        .CustomShelf,
        .EmptyShelf {
            z-index: 10;
            position: relative;
            width: 100%;
            height: 100%;
        }

        .PlacedProduct {
            position: absolute;
            bottom: 0;
            z-index: 5;

            img {
                user-select: none;
                position: absolute;
                bottom: 0;
                width: 64px;
            }

            &.PlacedProductFocused {
                z-index: 10;
            }
        }

        .PlacedProductPriceWrap {
            position: absolute;
            bottom: 0;
            z-index: 50;
            pointer-events: none;
            user-select: none;
        }

        .PlacedProductPrice {
            display: block;
            position: absolute;
            z-index: 50;
            width: auto;
            height: auto;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: none;
            white-space: nowrap;
            border: 1px solid transparent;

            .RTView {
                line-height: 1.9 !important;

                > div {
                    line-height: 1.9 !important;
                }
            }

            &.circle {
                border-radius: 50%;
                overflow: hidden;

                .RTView {
                    height: 0;
                    padding: 50% 5px 0;

                    > div {
                        margin-top: -0.85em;
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    padding-top: 50%;
                }
            }

            &.rect {
                padding: 2px 8px;
                border-radius: 6px;
            }

            &.rect-top-left {
                padding: 2px 6px 0;
                min-width: 64px;
                border-radius: 0 24px 24px 24px;
            }
        }

        .PlacedProductPromoPrice:not(.PlacedProductPromoPriceOnly) {
            &.circle {
                &:before {
                    content: '';
                    display: block;
                    padding-top: 12.5%;
                }

                .RTView {
                    padding-top: 25%;
                }

                &:after {
                    padding-top: 37.5%;
                }
            }

            &.rect,
            &.rect-top-left {
                .PlacedProductOldPrice {
                    margin-bottom: -0.5em;
                }
            }
        }

        .PlacedProductOldPrice {
            text-decoration: line-through;
        }

        .PlacedProductStockWrap {
            position: absolute;
            bottom: 0;
            z-index: 51;
            pointer-events: none;
            user-select: none;
        }

        .PlacedProductStock {
            display: block;
            position: absolute;
            z-index: 50;
            width: auto;
            height: auto;
            top: -5px;
            right: -5px;
            box-shadow: none;
            white-space: nowrap;
            border: 1px solid transparent;
            border-radius: 50%;
            overflow: hidden;

            .RTView {
                line-height: 1.9 !important;
                height: 0;
                padding: 50% 5px 0;

                > div {
                    line-height: 1.9 !important;
                    margin-top: -0.85em;
                }
            }

            &:after {
                content: '';
                display: block;
                padding-top: 50%;
            }
        }

        .PlacedProductNoBoxesVisible {
            &:after {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -6px;
                margin-top: -16px;
                content: '!';
                color: $white;
                background: $color-error;
                border-radius: 50%;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
                width: 12px;
                height: 12px;
            }
        }

        .ProductsShelfBase {
            display: block;
            background: transparent;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;

            &:after {
                content: '';
                display: block;
                user-select: none;
                position: absolute;
                top: 0;
                left: 0;
                right: -50px;
                height: 0;
            }
        }

        .SnapGuide {
            display: block;
            height: 0;
            width: 100%;
            border-bottom: 1px dashed $color-warn;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
        }
    }

    .FrameEyedropArea {
        position: absolute;
        z-index: 99;

        outline: 1px solid rgba(3, 94, 1, 0);
        box-shadow: 0 0 0 0 rgba(3, 94, 1, 0);

        transition:
            box-shadow 0.2s,
            outline 0.2s;

        &.FrameEyedropAreaJustAdded {
            outline: 1px solid rgba(0, 0, 0, 0.57);
            box-shadow: 0 0 7px 3px rgba(49, 131, 192, 0.3);
        }
    }
}

.AppCore .ProductWallDesigner .EditorCanvas {
    .FrameResizerTop,
    .FrameResizerLeft,
    .FrameResizerRight,
    .FrameResizerBottom {
        &:after {
            content: none;
            display: none;
        }
    }

    .FrameResizerTop,
    .FrameResizerLeft,
    .FrameResizerRight,
    .FrameResizerBottom,
    .FrameResizerTopLeft,
    .FrameResizerTopRight,
    .FrameResizerBottomLeft,
    .FrameResizerBottomRight,
    .FrameResizerMiddle {
        position: absolute;
        touch-action: none;
        opacity: 0.5;
    }

    .FrameResizerTopLeft,
    .FrameResizerTopRight,
    .FrameResizerBottomLeft,
    .FrameResizerBottomRight {
        &:after {
            display: none;
            content: '';
            width: 8px;
            height: 8px;
            border: 1px solid $white;
            background: $primary-blue;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -4px;
            margin-left: -4px;
        }
    }

    .FrameResizerTop {
        left: 0;
        right: 0;
        height: 5px;
        top: -3px;
        cursor: ns-resize;
    }

    .FrameResizerBottom {
        left: 0;
        right: 0;
        height: 5px;
        bottom: -3px;
        cursor: ns-resize;
    }

    .FrameResizerLeft {
        top: 0;
        bottom: 0;
        width: 5px;
        left: -3px;
        cursor: ew-resize;
    }

    .FrameResizerRight {
        top: 0;
        bottom: 0;
        width: 5px;
        right: -2px;
        cursor: ew-resize;
    }

    .FrameResizerMiddle {
        top: 8px;
        bottom: 8px;
        right: 8px;
        left: 8px;
        cursor: move;
    }

    .FrameResizerBottomLeft,
    .FrameResizerBottomRight,
    .FrameResizerTopLeft,
    .FrameResizerTopRight {
        width: 12px;
        height: 12px;
        left: -6px;
        top: -6px;
        cursor: nwse-resize;
    }

    .FrameResizerBottomRight,
    .FrameResizerTopRight {
        left: auto;
        right: -6px;
        cursor: nesw-resize;
    }

    .FrameResizerBottomLeft,
    .FrameResizerBottomRight {
        top: auto;
        bottom: -6px;
        cursor: nwse-resize;
    }

    .FrameResizerBottomLeft {
        cursor: nesw-resize;
    }

    .FrameResizer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
    }

    .Frame:hover .FrameResizer,
    .Frame .FrameResizer.FrameResizerActive {
        outline: 1px dashed $grey-light-2;

        .FrameResizerTopLeft,
        .FrameResizerTopRight,
        .FrameResizerBottomLeft,
        .FrameResizerBottomRight {
            &:after {
                display: block;
            }
        }
    }

    @include media-breakpoint-down(tablet) {
        .FrameResizerTopLeft,
        .FrameResizerTopRight,
        .FrameResizerBottomLeft,
        .FrameResizerBottomRight {
            &:after {
                width: 16px;
                height: 16px;
                margin-top: -9px;
                margin-left: -9px;
            }
        }

        .FrameResizerTop {
            height: 16px;
            top: -8px;
        }

        .FrameResizerLeft {
            width: 10px;
            left: -5px;
        }

        .FrameResizerRight {
            width: 10px;
            right: -5px;
        }

        .FrameResizerBottom {
            height: 16px;
            bottom: -8px;
        }

        .FrameResizerMiddle {
            top: 12px;
            bottom: 12px;
            right: 12px;
            left: 12px;
        }

        .FrameResizerBottomLeft,
        .FrameResizerBottomRight,
        .FrameResizerTopLeft,
        .FrameResizerTopRight {
            width: 16px;
            height: 16px;
            left: -8px;
            top: -8px;
        }

        .FrameResizerBottomLeft,
        .FrameResizerBottomRight {
            top: auto;
            bottom: -8px;
        }

        .FrameResizerBottomRight,
        .FrameResizerTopRight {
            left: auto;
            right: -8px;
        }
    }
}
