.AppCore .CustomPageDesigner {
    .ProductFrame {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        img {
            user-select: none;
            position: absolute;
            bottom: 0;
            width: 64px;
        }

        .PlacedProductPriceWrap {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 50;
            pointer-events: none;
            user-select: none;
        }

        .PlacedProductPrice {
            display: block;
            position: absolute;
            z-index: 50;
            width: auto;
            height: auto;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: none;
            white-space: nowrap;
            border: 1px solid transparent;

            .RTView {
                line-height: 1.9 !important;

                > div {
                    line-height: 1.9 !important;
                }
            }

            &.circle {
                border-radius: 50%;
                overflow: hidden;

                .RTView {
                    height: 0;
                    padding: 50% 5px 0;

                    > div {
                        margin-top: -0.85em;
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    padding-top: 50%;
                }
            }

            &.rect {
                padding: 2px 8px;
                border-radius: 6px;
            }

            &.rect-top-left {
                padding: 2px 6px 0;
                min-width: 64px;
                border-radius: 0 24px 24px 24px;
            }
        }

        .PlacedProductPromoPrice:not(.PlacedProductHideOriginalPrice) {
            &.circle {
                &:before {
                    content: '';
                    display: block;
                    padding-top: 12.5%;
                }

                .RTView {
                    padding-top: 25%;
                }

                &:after {
                    padding-top: 37.5%;
                }
            }

            &.rect,
            &.rect-top-left {
                .PlacedProductOldPrice {
                    margin-bottom: -0.5em;
                }
            }
        }

        .PlacedProductOldPrice {
            text-decoration: line-through;
        }
    }
}
