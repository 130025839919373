@import 'core';

.AppCore {
    .EditorCanvas {
        position: relative;
        width: 100%;
        height: 100%;

        .Frame {
            position: absolute;
        }
    }
}
