@import './colors/brand-color.scss';
@import './colors/dark-mode.scss';
@import './colors/light-mode.scss';
@import './colors/shadows.scss';
@import './colors/sizes.scss';

@import 'core';

body {
    background-color: $black;
}
