@import 'core';

.AppCore .ProductWallDesigner {
    .FrameEyedropArea {
        position: absolute;
        z-index: 99;

        outline: 1px solid rgba(3, 94, 1, 0);
        box-shadow: 0 0 0 0 rgba(3, 94, 1, 0);

        transition:
            box-shadow 0.2s,
            outline 0.2s;

        &.FrameEyedropAreaJustAdded {
            outline: 1px solid rgba(0, 0, 0, 0.57);
            box-shadow: 0 0 7px 3px rgba(49, 131, 192, 0.3);
        }
    }
}
