@import 'core';

.AppCore {
    .OnScreenKeyboard {
        width: 100%;
        max-width: 1080px;
        margin: 62px auto 0 auto;
    }

    .OnScreenKeyboardKey.Button.tertiary {
        height: 83px;
        width: 100%;
        border-radius: 22px;
        color: #061d5f;
        font-weight: 500;
        border-color: #c1c6d7;
        font-size: 24px;

        &:active {
            background-color: $grey-light-4;
        }

        .Icon {
            transform: rotate(180deg);
            color: #061d5f;
            width: 32px;
            height: 32px;
            line-height: 32px;
        }
    }

    .OnScreenKeyboardKeySpaceIcon {
        display: inline-block;
        vertical-align: bottom;
        width: 10%;
        height: 2px;
        background-color: #061d5f;
    }
}
