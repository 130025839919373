@import 'core';

.AppCore .TVScreenSearch {
    .ScreenTitle {
        margin-top: -57px;
    }

    .SearchResultImage {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        img {
            padding: 18px;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .SearchResultTitle {
        width: 100%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
