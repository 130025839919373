@import 'core';

.AppCore {
    .PlacedProductDragLayer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 1000;
        width: 62px;
        height: 62px;
        padding: 4px;
        pointer-events: none;
        margin-left: -31px;
        margin-top: -31px;
        background: $white;
        box-shadow: 0 2px 8px 0 rgba(207, 216, 223, 0.5);
        opacity: 0.8;

        > span {
            display: block;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
}
