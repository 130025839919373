@import 'core';

.AppCore .SlideshowViewer {
    position: relative;

    .SlideshowViewerSlidesWrap {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .SlideshowViewerSlide {
        width: 100%;
        height: 100%;
        background: $white;
        position: absolute;
        top: 0;
        left: 0;

        &.SlideshowViewerImage {
            display: flex;
            justify-content: center;
            align-items: center;

            > img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
    }

    .SlideshowViewerSlidePlaceholder {
        display: block;
    }

    .ProductWallDesigner > .Canvas,
    .CustomPageDesigner > .EditorCanvas {
        position: relative;
        transform-origin: top left;
    }

    .ProductWallDesigner > .Canvas {
        &.OnTopOfPreviewImage {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &.LivePreview {
        background: $white;
    }

    &.none {
        .SlideshowViewerSlide {
            z-index: 1;
            opacity: 0;

            &.SlideshowViewerSlideActive {
                z-index: 3;
                opacity: 1;
            }

            &.SlideshowViewerSlideNext,
            &.SlideshowViewerSlidePrev {
                z-index: 2;
            }
        }
    }

    &.slide {
        .SlideshowViewerSlide {
            left: -200%;
            opacity: 0;
            z-index: 0;

            &.SlideshowViewerSlidePrev,
            &.SlideshowViewerSlideActive,
            &.SlideshowViewerSlideNext {
                opacity: 1;
            }

            &.SlideshowViewerSlidePrev {
                left: -100%;
                z-index: 10;
            }

            &.SlideshowViewerSlideActive {
                left: 0;
                z-index: 15;
            }

            &.SlideshowViewerSlideNext {
                left: 100%;
                z-index: 10;
            }

            &.SlideshowViewerSlideEnableTransition {
                transition: left 0.5s;
            }
        }
    }

    &.fade {
        .SlideshowViewerSlide {
            opacity: 0;
            z-index: 0;

            &.SlideshowViewerSlideNext,
            &.SlideshowViewerSlidePrev {
                opacity: 0;
                z-index: 10;
            }

            &.SlideshowViewerSlideActive {
                opacity: 1;
                z-index: 15;
            }

            &.SlideshowViewerSlideEnableTransition {
                transition: opacity 0.5s;
            }
        }
    }

    &.zoom {
        .SlideshowViewerSlide {
            opacity: 0;
            z-index: 0;
            transform: scale(0.001);
            transform-origin: center;

            &.SlideshowViewerSlideNext,
            &.SlideshowViewerSlidePrev {
                opacity: 0;
                transform: scale(0.001);
                z-index: 10;
            }

            &.SlideshowViewerSlideActive {
                opacity: 1;
                transform: scale(1);
                z-index: 15;
            }

            &.SlideshowViewerSlideEnableTransition {
                transition:
                    transform 0.5s,
                    opacity 0.5s;
            }
        }
    }
}
