@import 'core';

.ProductWallDesigner {
    position: relative;
    overflow: hidden;

    .DesignerHeader {
        position: relative;
        z-index: 2;
        padding: 0;
        border-bottom: 1px solid $grey-light-1;
        box-shadow: 0 2px 8px 0 rgba(207, 216, 223, 0.5);
    }

    .DesignerHeaderItem {
        position: relative;
        z-index: 10;

        padding: 0 18px;
    }

    .DesignerHeaderItemProducts,
    .DesignerBodyItemProducts {
        width: 110px;
    }

    .DesignerHeaderItemCanvas {
        padding-left: 54px;
        white-space: nowrap;
    }

    .DesignerBody {
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    .DesignerBodyItem {
        color: inherit;
    }

    .DesignerBodyItemProducts {
        border-right: 1px solid $grey-light-1;

        .ProductsList {
            height: 840px;
        }
    }

    .DesignerBodyItemCanvas {
        height: 860px;
        padding: 38px 100px 38px 55px;
        background-color: $grey-light-4;
        z-index: 15;

        @include media-breakpoint-down(tablet) {
            padding: 38px 100px 18px 25px;
        }
    }

    .DesignerBodyItemEdit {
        position: absolute;
        z-index: 20;
        top: 0;
        right: 1px;
        bottom: 0;
        width: 0;
        transition: width 0.1s;
        background-color: $white;
        border-bottom-right-radius: 4px;
        overflow-x: hidden;

        > .Card {
            width: 349px;
            height: 100%;
            border: none;
            overflow: auto;

            > .CardItem {
                padding: 14px 17px 14px 14px !important;
                width: 100%;

                .RTControls {
                    > .container.gutter-14 > .Row > .Col {
                        padding: 6px;
                    }
                }
            }
        }
    }

    .DesignerBodyItemEditVisible {
        width: 349px;
        overflow-x: visible;
        border-left: 1px solid $grey-light-1;
    }

    .GFXTopLeft,
    .GFXTop,
    .GFXTopRight,
    .GFXLeft,
    .GFXRight,
    .GFXBottomLeft,
    .GFXBottom,
    .GFXBottomRight {
        display: none;
        background-size: contain;
    }

    .Frame.EyedropperEnabled {
        .FrameResizer > * {
            cursor: default;
        }
    }
}

.ProductWallDesigner.ProductWallDesignerPreviewMode,
.ProductWallDesigner.ProductWallDesignerLiveMode {
    max-width: none;

    .FrameResizer,
    .FrameActions,
    .ShelfMover,
    .PlacedProductActions,
    .PlacedProductResizer,
    .SplitShelfAction,
    .EmptyFrame > .Icon {
        display: none !important;
    }

    .Frame {
        outline: none !important;
    }
}

.ProductWallDesigner.ProductWallDesignerPreviewMode {
    .Canvas {
        pointer-events: none;
    }
}
