@import 'core';

.AppCore {
    .TVScreenProductEjectQueue {
        position: fixed;
        z-index: 999;
        top: 30px;
        right: 30px;
    }

    .TVScreenProductEjectJob {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        > div {
            width: 470px;

            > div:first-child {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 100%;
                background: #061d5f;
                border-radius: 3px;

                color: $white;
                font-size: 22px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 29px;
                text-align: center;

                > div {
                    padding: 12px 66px;
                }
            }

            > div:last-child {
                display: flex;
                width: 100%;
                margin-top: 2px;

                > span {
                    width: 234px;
                    height: 12px;
                    border-right: 2px solid $white;
                    background: #dce0e9;

                    &:last-child {
                        border-right: none;
                    }

                    &.TVScreenProductEjectFilledBar {
                        background: #061d5f;
                    }
                }
            }
        }

        &.failed {
            > div {
                > div:first-child {
                    background: #cc1f51;
                }
            }
        }
    }
}
