@import 'core';

.AppCore {
    .PreloaderProgressWrap {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        background-color: $black;
        align-items: center;
        justify-content: center;

        &.PreloaderProgressWrapFixed {
            position: fixed;
        }
    }

    .PreloaderProgress {
        height: 60px;
        width: 60px;
        border: 3px solid $white;
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    @-webkit-keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
