@import 'core';

.CustomPageDesigner {
    .ShelfOptionBackgroundImage {
        .SelectHeaderLabel img,
        .MenuItem img {
            margin-right: 10px;
            max-width: 30px;
            max-height: 30px;
        }
    }

    .ToggleGroup {
        cursor: pointer;
    }
}
