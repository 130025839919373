@import 'core';

.AppCore {
    .DraggableBlockDragLayer {
        position: fixed;
        z-index: 1000;
        pointer-events: none;
        transform: translateX(-50%) translateY(-50%);
        background: $white;
        box-shadow: 0 2px 8px 0 rgba(207, 216, 223, 0.5);
        opacity: 0.8;
    }
}
