@import 'core';

.AppCore {
    .SlideshowPreloaderMessage {
        background: $white;
        padding: 20px 30px;
        border-radius: 5px;
        font-size: 28px;
        max-width: 700px;
    }

    .SlideshowViewer {
        z-index: 1;
    }

    .InteractiveScreen {
        z-index: 2;
    }
}
