@import 'core';

.AppCore .ProductWallDesigner {
    .EditorCanvas {
        .Frame {
            &.FocusedFrame {
                display: block;
            }

            .RTView {
                position: relative;
                z-index: 5;
                overflow: hidden;

                ul,
                ol {
                    padding: 0 0 0 1.5em;
                }

                strong {
                    font-weight: 600;
                }
            }

            .FrameResizer {
                z-index: 10;
            }

            .RTEditor {
                position: relative;
                z-index: 15;
                overflow: hidden;

                .HTMLEditor {
                    position: relative;
                    background: transparent;
                    border: none;

                    [class^='RichTextEditor__editor___'] {
                        background: transparent;
                        font-size: inherit;
                        height: 100%;
                    }

                    [class^='EditorToolbar__'] {
                        display: none;
                    }

                    [style*='font-weight: bold;'] {
                        font-weight: 600 !important;
                    }

                    .public-DraftEditor-content {
                        padding: 0;
                        overflow: hidden;
                    }

                    .DraftEditor-editorContainer {
                        border: none;
                    }
                }
            }

            &.image {
                .stretch-fit,
                .proportional-inside {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }
            }

            .EmptyFrame {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .Icon {
                    color: $grey-dark-2;
                }
            }
        }
    }
}
