@import 'core';

.AppCore {
    .CustomPageDesigner {
        .BuilderWrap {
            height: 100%;
        }

        .EditorCanvas {
            z-index: 2;

            .Frame.EyedropperEnabled {
                .FrameResizer > * {
                    cursor: default;
                }
            }
        }

        .ToggleButton .Button.tertiary {
            border-color: $grey-dark-2;
        }
    }

    .CustomPageDesignerPreviewMode {
        .Canvas {
            pointer-events: none;
        }

        .FrameResizer,
        .FrameActions {
            display: none !important;
        }

        .Frame {
            outline: none !important;
        }
    }

    .PreviewText {
        display: inline-block;
        max-width: 380px;
    }
}
