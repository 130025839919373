@import 'core';

.AppCore .TVSlidesOverview {
    .ScreenTitle {
        margin-top: -57px;
    }

    .SearchResult {
        position: relative;
        padding-bottom: 51px;
    }

    .SearchResultImage {
        > img,
        > div {
            max-width: 100%;
            max-height: 100%;
        }

        > img,
        > div,
        > div img {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        > div {
            background: $black;
        }
    }

    .SearchResultTitle {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
