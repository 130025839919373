@import 'core';

.ProductWallDesigner {
    .DropArea {
        display: block;
        position: absolute;
        height: 100%;

        .PlacedProduct {
            pointer-events: none;
        }
    }

    .DropAreaActive {
        outline: 1px dashed $grey-light-4;
        background: $white;
        opacity: 0.4;
    }
}
