@import 'core';

.ProductWallDesigner {
    .PlacedProductResizerTop,
    .PlacedProductResizerLeft,
    .PlacedProductResizerRight,
    .PlacedProductResizerTopLeft,
    .PlacedProductResizerTopRight,
    .PlacedProductResizerMiddle {
        position: absolute;
        top: 0;
        touch-action: none;
        opacity: 0.5;

        &:after {
            display: none;
            content: '';
            width: 8px;
            height: 8px;
            border: 1px solid $white;
            background: $primary-blue;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -4px;
            margin-left: -4px;
        }
    }

    .PlacedProductResizerTop {
        left: 0;
        right: 0;
        height: 5px;
        top: -3px;
        cursor: ns-resize;
    }

    .PlacedProductResizerLeft {
        bottom: 0;
        width: 5px;
        left: -3px;
        cursor: ew-resize;
    }

    .PlacedProductResizerRight {
        bottom: 0;
        width: 5px;
        right: -2px;
        cursor: ew-resize;
    }

    .PlacedProductResizerMiddle {
        top: 8px;
        bottom: 0;
        right: 8px;
        left: 8px;
        cursor: move;
    }

    .PlacedProductResizerTopLeft,
    .PlacedProductResizerTopRight {
        width: 12px;
        height: 12px;
        left: -6px;
        top: -6px;
        cursor: nwse-resize;
    }

    .PlacedProductResizerTopRight {
        left: auto;
        right: -6px;
        cursor: nesw-resize;
    }

    .PlacedProductResizer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;

        &.PlacedProductResizerEmphasize {
            outline: 1px dashed $grey-light-4;
        }

        &.PlacedProductResizerDisabled {
            pointer-events: none;
        }
    }

    .PlacedProduct:hover .PlacedProductResizer:not(.PlacedProductResizerEmphasize),
    .PlacedProduct .PlacedProductResizer.PlacedProductResizerActive {
        outline: 1px dashed $grey-light-2;

        .PlacedProductResizerTop,
        .PlacedProductResizerLeft,
        .PlacedProductResizerRight,
        .PlacedProductResizerTopLeft,
        .PlacedProductResizerTopRight {
            &:after {
                display: block;
            }
        }
    }

    @include media-breakpoint-down(tablet) {
        .PlacedProductResizerTop,
        .PlacedProductResizerLeft,
        .PlacedProductResizerRight,
        .PlacedProductResizerTopLeft,
        .PlacedProductResizerTopRight,
        .PlacedProductResizerMiddle {
            &:after {
                width: 16px;
                height: 16px;
                margin-top: -9px;
                margin-left: -9px;
            }
        }

        .PlacedProductResizerTop {
            height: 16px;
            top: -8px;
        }

        .PlacedProductResizerLeft {
            width: 10px;
            left: -5px;
        }

        .PlacedProductResizerRight {
            width: 10px;
            right: -5px;
        }

        .PlacedProductResizerTopLeft,
        .PlacedProductResizerTopRight {
            width: 16px;
            height: 16px;
            left: -8px;
            top: -8px;
        }

        .PlacedProductResizerTopRight {
            left: auto;
            right: -8px;
        }
    }
}
