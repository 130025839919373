@import 'core';

.ProductWallDesigner {
    .ShelfMover {
        user-select: none;
        position: absolute;
        z-index: 5;
        bottom: 0;
        left: 0;
        right: -45px;
        border-top: 1px dashed $grey-light-1;
        border-bottom: 1px dashed $grey-light-1;

        @include media-breakpoint-down(tablet) {
            right: -35px;
        }
    }

    .BodyArea.customShelf .ShelfMover {
        border-top: none;
    }

    .ShelfMoverHandle {
        user-select: none;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        cursor: row-resize;
        border-bottom: none;
        touch-action: none;

        .Icon {
            user-select: none;
            position: absolute;
            right: 0;
            width: 20px;
            height: 20px;
            color: $grey-dark-2;
        }
    }

    .ShelfMoverOtherActions {
        position: absolute;
        left: 100%;

        a {
            display: block;
            margin-left: 14px;
            margin-top: -2px;
            border-bottom: none;

            @include media-breakpoint-down(tablet) {
                margin-left: 7px;
            }

            .Icon {
                user-select: none;
                color: $grey-dark-2;
            }

            &:hover .Icon {
                color: $grey-dark-1;
            }
        }
    }

    .ShelfMoverHandle,
    .ShelfMoverOtherActions {
        top: 100%;
        margin-top: -20px;
    }
}
