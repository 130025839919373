@import 'core';

.AppCore .EditorCanvas {
    .FrameResizerTop,
    .FrameResizerLeft,
    .FrameResizerRight,
    .FrameResizerBottom,
    .FrameResizerTopLeft,
    .FrameResizerTopRight,
    .FrameResizerBottomLeft,
    .FrameResizerBottomRight,
    .FrameResizerMiddle {
        position: absolute;
        touch-action: none;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        opacity: 0.5;
    }

    .FrameResizerTop,
    .FrameResizerBottom,
    .FrameResizerLeft,
    .FrameResizerRight {
        opacity: 1;

        &:after {
            content: '';
            display: none;
            position: absolute;
            top: -3px;
            left: 50%;
            margin-left: -14px;
            width: 28px;
            height: 10px;
            background: $grey-light-1;
            border-radius: 4px;
        }

        &:hover:after {
            background: $primary-blue;
        }
    }

    .FrameResizerLeft,
    .FrameResizerRight {
        &:after {
            top: 50%;
            left: -2px;
            margin-left: 0;
            margin-top: -14px;
            width: 10px;
            height: 28px;
        }
    }

    .FrameResizerTop {
        left: 0;
        right: 0;
        height: 5px;
        top: -3px;
        cursor: ns-resize;
    }

    .FrameResizerBottom {
        left: 0;
        right: 0;
        height: 5px;
        bottom: -3px;
        cursor: ns-resize;
    }

    .FrameResizerLeft {
        top: 0;
        bottom: 0;
        width: 5px;
        left: -3px;
        cursor: ew-resize;
    }

    .FrameResizerRight {
        top: 0;
        bottom: 0;
        width: 5px;
        right: -2px;
        cursor: ew-resize;
    }

    .FrameResizerMiddle {
        top: 8px;
        bottom: 8px;
        right: 8px;
        left: 8px;
        cursor: move;
    }

    .FrameResizerBottomLeft,
    .FrameResizerBottomRight,
    .FrameResizerTopLeft,
    .FrameResizerTopRight {
        width: 12px;
        height: 12px;
        left: -6px;
        top: -6px;
        cursor: nwse-resize;
    }

    .FrameResizerBottomRight,
    .FrameResizerTopRight {
        left: auto;
        right: -6px;
        cursor: nesw-resize;
    }

    .FrameResizerBottomLeft,
    .FrameResizerBottomRight {
        top: auto;
        bottom: -6px;
        cursor: nwse-resize;
    }

    .FrameResizerBottomLeft {
        cursor: nesw-resize;
    }

    .FrameResizer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
    }

    .Frame:hover .FrameResizer,
    .Frame .FrameResizer.FrameResizerActive,
    .Frame.FocusedFrame .FrameResizer {
        .FrameResizerTop,
        .FrameResizerBottom,
        .FrameResizerLeft,
        .FrameResizerRight {
            &:after {
                display: block;
            }
        }
    }

    @include media-breakpoint-down(tablet) {
        .FrameResizerTop,
        .FrameResizerBottom {
            &:after {
                width: 36px;
                height: 16px;
                top: 0;
                margin-left: -18px;
            }
        }

        .FrameResizerLeft,
        .FrameResizerRight {
            &:after {
                height: 36px;
                width: 16px;
                left: -2px;
                margin-top: -18px;
            }
        }

        .FrameResizerTop {
            height: 16px;
            top: -8px;
        }

        .FrameResizerLeft {
            width: 10px;
            left: -5px;
        }

        .FrameResizerRight {
            width: 10px;
            right: -5px;
        }

        .FrameResizerBottom {
            height: 16px;
            bottom: -8px;
        }

        .FrameResizerMiddle {
            top: 12px;
            bottom: 12px;
            right: 12px;
            left: 12px;
        }

        .FrameResizerBottomLeft,
        .FrameResizerBottomRight,
        .FrameResizerTopLeft,
        .FrameResizerTopRight {
            width: 16px;
            height: 16px;
            left: -8px;
            top: -8px;
        }

        .FrameResizerBottomLeft,
        .FrameResizerBottomRight {
            top: auto;
            bottom: -8px;
        }

        .FrameResizerBottomRight,
        .FrameResizerTopRight {
            left: auto;
            right: -8px;
        }
    }
}
