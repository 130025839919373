@import 'core';

.AppCore {
    .EditorHistoryActions {
        display: flex;
        align-items: center;
        .Tooltip {
            width: 100%;
        }
    }
}
