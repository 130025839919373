@import 'core';

.AppCore {
    .CustomPageDesignerBuilderBlock {
        display: block;
        border: 1px solid $grey-light-1;
        background: $white;
        border-radius: 4px;
        padding: 6px;
        text-align: center;
        cursor: move;

        &.tall {
            padding: 14px;
        }

        .Icon {
            margin-bottom: 8px;
            color: $grey-dark-2;
        }

        &:hover {
            border-color: $primary-blue;
            color: $primary-blue;

            .Icon {
                color: $primary-blue;
            }
        }
    }
}
