@import 'core';

.AppCore .CustomPageDesigner {
    &.LivePreview,
    &.CustomPageDesignerPreviewMode {
        position: relative;

        .EditorCanvas {
            position: relative;
            z-index: 1;
        }

        .TouchTargets {
            transform-origin: top left;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            z-index: 2;

            .Body {
                position: relative;
            }

            .PlacedProduct {
                position: absolute;
                bottom: 0;
                z-index: 5;
                cursor: pointer;
                border-bottom: none;
            }
        }
    }
}
