@import 'core';

.ProductWallDesigner {
    .Canvas {
        &.style3D {
            $bg-color: transparent;

            .BodyArea,
            .SplitShelfAction {
                background-color: inherit;
            }

            .GFXLeft,
            .GFXRight,
            .GFXTop,
            .GFXBottom,
            .GFXBottomLeft,
            .GFXBottomRight,
            .GFXTopLeft,
            .GFXTopRight {
                display: none;
                background-color: inherit;
                background-position: 0 0;
                background-repeat: no-repeat;
            }

            .Body {
                background-color: $bg-color;

                > .GFXBottom,
                > .GFXBottomLeft,
                > .GFXBottomRight,
                > .GFXLeft,
                > .GFXRight {
                    display: block;
                    position: absolute;
                    z-index: 0;
                }

                > .GFXLeft,
                > .GFXRight {
                    width: 70px;
                    top: 0;
                    bottom: 0;
                    z-index: 0;
                }

                > .GFXLeft {
                    left: 0;
                    background-image: url('/static/images/shelves-v1/3d/left.png');
                    background-repeat: repeat-y;
                }

                > .GFXRight {
                    right: 0;
                    background-image: url('/static/images/shelves-v1/3d/right.png');
                    background-repeat: repeat-y;
                }
            }

            .Body > .BodyArea:nth-child(2) {
                > .GFXLeft,
                > .GFXRight,
                > .GFXTop,
                > .GFXBottom,
                > .GFXBottomLeft,
                > .GFXBottomRight,
                > .GFXTopLeft,
                > .GFXTopRight {
                    z-index: 0;
                }

                > .GFXLeft,
                > .GFXRight {
                    top: 35px;
                }

                > .GFXTop,
                > .GFXTopLeft,
                > .GFXTopRight {
                    display: block;
                    position: absolute;
                    top: 0;
                    height: 35px;
                }

                > .GFXTop {
                    left: 70px;
                    right: 70px;
                    background-image: url('/static/images/shelves-v1/3d/top.png');
                    background-repeat: repeat-x;
                    z-index: 5;
                }

                > .GFXTopLeft {
                    left: 0;
                    width: 70px;
                    background-image: url('/static/images/shelves-v1/3d/top-left.png');
                    z-index: 10;
                }

                > .GFXTopRight {
                    right: 0;
                    width: 70px;
                    background-image: url('/static/images/shelves-v1/3d/top-right.png');
                    z-index: 10;
                }

                > .GFXBottom,
                > .GFXBottomLeft,
                > .GFXBottomRight {
                    z-index: 15;
                }
            }

            > .Body > .BodyArea {
                z-index: 15;
            }

            > .Body > .BodyArea,
            .SplitShelfAction .BodyArea {
                .ProductsShelf,
                .CustomShelf {
                    z-index: 30;
                }

                .SplitShelfAction {
                    z-index: 35;
                }

                .ShelfMover {
                    z-index: 32;
                }

                > .GFXLeft,
                > .GFXRight,
                > .GFXTop,
                > .GFXBottom,
                > .GFXBottomLeft,
                > .GFXBottomRight,
                > .GFXTopLeft,
                > .GFXTopRight {
                    display: block;
                    position: absolute;
                    z-index: 0;
                }

                > .GFXBottom,
                > .GFXBottomLeft,
                > .GFXBottomRight {
                    bottom: 0;
                    height: 35px;
                }

                > .GFXBottom {
                    left: 70px;
                    right: 70px;
                    background-image: url('/static/images/shelves-v1/3d/shelf-middle.png');
                    background-repeat: repeat-x;
                    z-index: 5;
                }

                > .GFXBottomLeft {
                    left: 0;
                    width: 70px;
                    background-image: url('/static/images/shelves-v1/3d/shelf-left.png');
                    background-color: transparent;
                    z-index: 10;
                }

                > .GFXBottomRight {
                    right: 0;
                    width: 70px;
                    background-image: url('/static/images/shelves-v1/3d/shelf-right.png');
                    background-color: transparent;
                    z-index: 10;
                }

                > .GFXLeft,
                > .GFXRight {
                    z-index: 0;
                    top: 0;
                    bottom: 0;
                    width: 70px;
                }

                > .GFXLeft {
                    left: 0;
                    background-image: url('/static/images/shelves-v1/3d/left.png');
                    background-repeat: repeat-y;
                }

                > .GFXRight {
                    right: 0;
                    background-image: url('/static/images/shelves-v1/3d/right.png');
                    background-repeat: repeat-y;
                }

                &.LastBodyArea {
                    z-index: 14;

                    > .GFXLeft,
                    > .GFXRight {
                        bottom: 26px;
                    }

                    > .GFXBottom,
                    > .GFXBottomLeft,
                    > .GFXBottomRight {
                        bottom: 0;
                        height: 26px;
                    }

                    > .GFXBottom {
                        left: 70px;
                        right: 70px;
                        background-image: url('/static/images/shelves-v1/3d/bottom.png');
                        background-repeat: repeat-x;
                        z-index: 5;
                    }

                    > .GFXBottomLeft {
                        left: 0;
                        width: 70px;
                        background-image: url('/static/images/shelves-v1/3d/bottom-left.png');
                        z-index: 10;
                    }

                    > .GFXBottomRight {
                        right: 0;
                        width: 70px;
                        background-image: url('/static/images/shelves-v1/3d/bottom-right.png');
                        z-index: 10;
                    }
                }
            }

            .CustomShelf {
                background: $white;
            }

            .ProductsShelfBase {
                background: transparent;
            }

            .SplitShelfAction {
                .BodyArea {
                    left: 0;
                    top: 21px;
                    height: 45px;
                    z-index: 30 !important;

                    > .GFXLeft,
                    > .GFXRight {
                        display: none;
                    }
                }
            }
        }
    }
}
