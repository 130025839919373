@import 'core';

.AppCore {
    .FrameActionsWrap {
        position: absolute;
        top: -30px;
        right: calc(50% + 34px);
    }

    .FrameActions {
        display: block;
        min-width: 68px;
        text-align: center;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 6px;
        }

        a {
            border-bottom: none;
            display: inline-block;
            margin: 0 5px;
            width: 24px;
            height: 24px;
            background: $grey-light-1;
            border-radius: 50%;
            line-height: 24px;
            text-align: center;
            opacity: 0.7;

            &:hover,
            &:active,
            &:focus {
                opacity: 1;
            }

            .Icon {
                color: $grey-dark-1;
                width: 14px;
                height: 14px;
                line-height: 14px;
            }
        }
    }

    @include media-breakpoint-down(tablet) {
        .FrameActionsWrap {
            top: -22px;
            right: 100px;
        }

        .FrameActions {
            min-width: 98px;

            a {
                width: 38px;
                height: 38px;
                line-height: 38px;

                .Icon {
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                }
            }
        }
    }
}
