@import 'core';

.AppCore {
    .Button {
        $primary-blue: #1f64cc;
        $primary-blue-active: #1a4fa0;
        $grey-light-1: #cfd8df;
        $grey-dark-1: #222932;
        $grey-dark-2: #89969f;

        display: inline-block;
        text-align: center;
        white-space: nowrap;
        vertical-align: top;
        user-select: none;
        border: 0 solid transparent;
        border-radius: 4px;
        font-size: 19px;
        line-height: 24px;
        font-weight: 500;
        transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out;
        text-decoration: none;
        box-shadow: none;

        &:hover,
        &:focus {
            text-decoration: none;
            outline: none;
        }

        &.disabled,
        &:disabled {
            opacity: 0.6;
            box-shadow: none;
            pointer-events: none;
        }

        &:not(:disabled):not(.disabled) {
            cursor: pointer;
        }

        &.xl {
            padding: 24px 32px;
            font-size: 29px;
            line-height: 31px;
        }

        &.l {
            padding: 14px 22px;
        }

        &.primary {
            color: $white;
            background-color: $primary-blue;

            &.active,
            &:focus,
            &:hover {
                background-color: $primary-blue-active;
            }

            &.disabled,
            &:disabled {
                opacity: 0.34;
                background-color: $primary-blue;
            }
        }

        &.tertiary {
            color: $grey-dark-1;
            background-color: $white;
            border-width: 1px;
            border-color: $grey-light-1;

            &.active,
            &:focus,
            &:hover {
                border-color: $grey-dark-2;
            }

            &.disabled,
            &:disabled {
                opacity: 0.34;
                background-color: $white;
                border-color: $grey-light-1;
            }

            &.xl {
                padding: 23px 30px;
            }

            &.l {
                padding: 13px 20px;
            }
        }

        &.plain {
            color: $grey-dark-1;
            background-color: transparent;

            &.active,
            &:focus,
            &:hover {
                opacity: 0.8;
            }

            &.disabled,
            &:disabled {
                opacity: 0.34;
            }
        }

        .Icon:not(#hack) {
            display: inline-block;
            width: 36px;
            height: 36px;
            line-height: 36px;
            color: inherit;
            transition: color 0.15s ease-in-out;
            vertical-align: middle;
        }

        &.tertiary .Icon:not(#hack) {
            color: $grey-dark-2;
        }
    }
}
