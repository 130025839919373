@import 'core';

.ProductWallDesigner {
    .ProductsList {
        overflow-x: hidden;
        overflow-y: auto;
        padding: 7px 0;
    }

    .ProductsListItem {
        position: relative;
        padding: 9px 16px;
        cursor: move;

        > .ProductWallImage {
            border: 2px solid transparent;
            border-radius: 4px;
            touch-action: none;
            width: 70px;
            height: 76px;
            padding: 2px;

            > img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            &.DisabledDraggableImage {
                > div {
                    opacity: 0.6;
                }
            }
        }

        &.ProductsListItemWithBadge {
            > div,
            > span {
                border-color: $grey-light-3;
            }
        }

        &:hover {
            .DraggableImage {
                border-color: $primary-blue-light;
            }
            .DisabledDraggableImage {
                border-color: $grey-light-2;
                cursor: not-allowed;
            }
        }
    }

    .ProductListItemCountBadge {
        position: absolute;
        right: 10px;
        bottom: 4px;

        display: block;
        width: 13px;
        height: 13px;

        background: $primary-blue;
        border-radius: 50%;
        color: $white;
        font-size: 9px;
        text-align: center;
        line-height: 13px;
    }
}
