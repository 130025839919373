@import 'core';

.CustomPageDesigner {
    .ShelfFrame {
        &.style3D {
            $bg-color: transparent;

            .GFXLeft,
            .GFXRight,
            .GFXTop,
            .GFXBottom,
            .GFXBottomLeft,
            .GFXBottomRight,
            .GFXTopLeft,
            .GFXTopRight {
                display: none;
                background-color: inherit;
                background-position: 0 0;
                background-repeat: no-repeat;
            }

            > .GFXBottom,
            > .GFXBottomLeft,
            > .GFXBottomRight {
                display: block;
                position: absolute;
                z-index: 0;
            }

            > .GFXBottom,
            > .GFXBottomLeft,
            > .GFXBottomRight {
                bottom: 0;
                height: 36px;
            }

            > .GFXBottom {
                left: 70px;
                right: 70px;
                background-image: url('/static/images/shelves-v1/3d/shelf-middle.png');
                background-repeat: repeat-x;
                background-size: contain;
                z-index: 5;
            }

            > .GFXBottomLeft {
                left: 0;
                width: 70px;
                background-image: url('/static/images/shelves-v1/3d/shelf-left.png');
                background-size: contain;
                z-index: 10;
            }

            > .GFXBottomRight {
                right: 0;
                width: 70px;
                background-image: url('/static/images/shelves-v1/3d/shelf-right.png');
                background-size: contain;
                z-index: 10;
            }
        }
    }
}
