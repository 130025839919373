@import 'core';

.AppCore {
    .ManageScreen {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .ManageScreenButton {
            text-align: center;
        }

        .ManageScreenSection {
            margin-top: 60px;
        }
    }
}
