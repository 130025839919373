@import 'core';

.ProductWallDesigner {
    .SplitShelfAction {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 35;

        border-bottom: none;
        text-align: center;

        .BodyArea {
            display: block;
            height: 20px;
            opacity: 0.1;
            border-bottom: none;
        }
    }

    .BodyArea:hover {
        .SplitShelfAction {
            .BodyArea {
                opacity: 0.2;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}
