@import 'core';

.AppCore {
    .CustomPageDesignerBuildMenuBackArrow {
        display: block;
        border-bottom: none;

        .Icon {
            margin-right: 14px;

            svg {
                transform: rotate(180deg);
                color: $grey-dark-2;
            }
        }

        &:hover,
        &:active {
            color: $grey-dark-1;

            .Icon svg {
                color: $grey-dark-1;
            }
        }
    }

    .CustomPageDesignerBuildMenu {
        min-width: 380px;

        .SmallInputWrap {
            input {
                padding-top: 6px;
                padding-bottom: 6px;
                min-width: 100px;
            }
        }

        .AlignButton {
            > span {
                display: flex;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.FlexCenter {
                    justify-content: center;
                }

                &.FlexEnd {
                    justify-content: flex-end;
                }

                > span {
                    display: block;
                    vertical-align: top;
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    background: $grey-light-1;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &.AlignButtonActive > span > span {
                background: $primary-blue;
            }
        }

        .SliderWrap .InputBaseDescription {
            margin-top: 0;
        }

        .InputBaseLabel {
            margin-bottom: 0;
        }

        .InputBaseWrapper .InputBaseLabel {
            margin-bottom: 5px;
        }

        .CheckboxLabel .InputBaseLabel {
            display: inline;
            line-height: 18px;
        }

        .BackgroundImageSelect {
            .SelectHeaderLabel img,
            .MenuItem img {
                margin-right: 10px;
                max-width: 30px;
                max-height: 30px;
            }
        }

        .Toggle .InputBaseWrapperInner {
            display: flex;

            .ToggleLabel {
                flex: 1;
            }
        }

        .VisionStyleToolbar {
            > div {
                > .container {
                    display: inline-block;

                    > .Row {
                        > .Col {
                            padding: 7px;

                            > .ToggleButton .Button > .Icon.m {
                                margin-left: -10px !important;
                                margin-right: -10px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .CustomPageDesignerBuildMenuImageThumbnail {
        position: relative;
        width: 100%;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        img {
            object-fit: contain;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            position: absolute;
            top: 2px;
            left: 2px;
            bottom: 2px;
            right: 2px;
        }

        &.Selected {
            border-color: $primary-blue;
        }
    }

    .CustomPageDesignerBuildMenuHotSpotSlidePreview {
        position: relative;
        width: 100%;
        height: 300px;
        margin-top: 10px;

        .MediaItemPreview {
            height: 280px !important;
        }

        img {
            object-fit: contain;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            position: absolute;
            top: 2px;
            left: 2px;
            bottom: 2px;
            right: 2px;
        }
    }

    .CustomPageDesignerBuildMenuHotSpotSlidePicker,
    .CustomPageDesignerBuildMenuHotSpotSlidePreview {
        max-width: 443px;
    }
}
