@import 'core';

.ProductWallDesigner {
    .PlacedProductDropArea {
        display: block;
        position: absolute;
        z-index: 50;
        height: 100%;
        width: 100%;
    }
}
