@import 'core';

.AppCore .EditorCanvas {
    .Frame {
        display: block;
        position: absolute;
        z-index: 38;
        outline: 1px dashed $grey-light-1;

        &.FocusedFrame {
            display: block;

            .FrameResizer > * {
                z-index: 40;
            }
        }
        &.emergencyAlert {
            min-height: 180px;
        }
    }
}
