@import 'core';

.AppCore {
    .InteractiveScreenNavigation {
        position: fixed;
        z-index: 999;
        bottom: 30%;
        right: 0;
        width: 100px;
        margin-top: -10px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 5px 0;
        background: $white;
        box-shadow: 0 3px 5px 0 #e0e3f3;

        .Button {
            width: 100px;
            padding: 0 12px;
            margin-bottom: 12px;

            > span {
                display: block;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
                color: #9ea1aa;
                font-size: 20px;
                line-height: 23px;
            }

            > .Icon {
                width: 50px;
                height: 50px;
                line-height: 50px;
                color: #29375f;
            }
        }

        &.AccessibilityMode {
            bottom: 18%;
        }
    }
}
