@import 'core';

.AppCore {
    .TVScreenProductDetails {
        .ProductDetailsImageGallery {
            position: relative;
            width: 684px;
            margin: 62px auto 0 auto;
        }

        .ProductDetailsImageGalleryImage {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 507px;
            height: 507px;
            margin: 0 auto;
            border: 1px solid #f0f2fb;
            background-color: $white;
            box-shadow: 0 6px 18px 0 #e0e3f3;

            img {
                display: block;
                padding: 20px;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .ProductDetailsImageGalleryNav {
            position: absolute;
            top: 50%;
            margin-top: -36px;
            border-bottom: none;

            .Icon {
                height: 72px;
                width: 72px;
                line-height: 72px;
                color: #061d5f;
            }
        }

        .ProductDetailsImageGalleryNavPrev {
            left: 0;
        }

        .ProductDetailsImageGalleryNavNext {
            right: 0;
        }

        .ProductDetailsImageGalleryNavDisabled {
            pointer-events: none;
            opacity: 0.5;
        }

        .ProductDetailsProductTitle {
            margin-top: 65px;
        }

        .ProductDetailsIdentifiers {
            color: #9ea1aa;
            font-size: 21px;
            line-height: 26px;
            text-align: center;

            span {
                display: inline-block;

                &:after {
                    margin: 0 10px;
                    content: '|';
                }

                &:last-child:after {
                    content: '';
                    margin: 0;
                }
            }
        }

        .ProductDetailsPrice {
            margin-top: 35px;
            display: flex;
            justify-content: center;

            > span {
                padding: 15px 22px 19px 22px;
                border-radius: 3px;
                color: #061d5f;
                font-size: 25px;
                font-weight: 500;
                line-height: 34px;
            }
        }

        .ProductDetailsPriceOld {
            text-decoration: line-through;
            font-size: 21px;
            line-height: 30px;
            margin-top: 0;
        }

        .ProductDetailsStock {
            margin-top: 55px;
            display: flex;
            justify-content: center;

            > * {
                margin-right: 19px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .ProductDetailsStockNumber {
                display: block;
                font-size: 22px;
                line-height: 67px;
                color: #061d5f;
                border: 1px solid #ced2f7;
                border-radius: 3px;
                padding: 0 22px;

                > .Icon {
                    margin-right: 10px;
                    color: #061d5f;
                }
            }

            .Button {
                flex: 0;

                &.primary {
                    font-size: 23px;
                    line-height: 28px;
                    color: #061d5f;
                    background-color: #a7dfe7;

                    &:disabled {
                        opacity: 0.8;
                    }
                }
            }
        }

        .ProductDetailsContentTabs {
            margin-top: 62px;
            border: 1px solid #e9f7f9;
            border-radius: 3px;
            background-color: $white;

            > ul {
                display: flex;
                list-style-type: none;
                padding: 0;
                margin: 0;

                > li {
                    flex: 1;
                    margin-right: 1px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .ProductDetailsContentTab {
            > a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 18px 8px;
                background-color: #e9f7f9;
                color: #061d5f;
                font-size: 23px;
                text-align: center;
                line-height: 27px;
                border-bottom: none;

                > span {
                }
            }

            &:first-child > a {
                border-top-left-radius: 3px;
            }

            &:last-child > a {
                border-top-right-radius: 3px;
            }
        }

        .ProductDetailsContentActiveTab {
            > a {
                background-color: $white;
            }
        }

        .ProductDetailsContentTabsContent {
            padding: 49px 32px;
            color: #9ea1aa;
            font-size: 21px;
            letter-spacing: 0;
            line-height: 26px;
            max-height: 608px;
            overflow: auto;

            > h2 {
                margin-bottom: 26px;
                color: #061d5f;
                font-size: 25px;
                font-weight: 500;
                line-height: 28px;
            }
        }

        .InteractiveScreenHeader:nth-last-child(2) {
            position: absolute;
            bottom: 65px;
            left: 56px;
            right: 56px;
        }

        .ProductDetailsContentSidesWrap {
            &.x-1920x1080,
            &.x-2112x1048 {
                display: flex;
                margin: 0 80px 0 0;

                .ProductDetailsContentTop,
                .ProductDetailsContentBottom {
                    flex: 1;
                    width: 50%;
                }

                .ProductDetailsContentTabsContent {
                    max-height: 572px;
                }
            }
        }
    }
}
