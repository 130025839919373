@import 'core';

.ProductWallDesigner {
    .Canvas {
        &.style2D {
            $bg-color: transparent;

            .GFXLeft,
            .GFXRight,
            .GFXTop,
            .GFXBottom,
            .GFXBottomLeft,
            .GFXBottomRight,
            .GFXTopLeft,
            .GFXTopRight {
                display: none;
                background-color: inherit;
                background-position: 0 0;
                background-repeat: no-repeat;
            }

            .Body {
                background-color: $bg-color;
            }

            > .Body > .BodyArea {
                z-index: 15;
            }

            > .Body > .BodyArea,
            .SplitShelfAction .BodyArea {
                .ProductsShelf,
                .CustomShelf {
                    z-index: 30;
                }

                .SplitShelfAction {
                    z-index: 35;
                }

                .ShelfMover {
                    z-index: 32;
                }

                &:not(.LastBodyArea) {
                    > .GFXBottom,
                    > .GFXBottomLeft,
                    > .GFXBottomRight {
                        display: block;
                        position: absolute;
                        z-index: 0;

                        bottom: -3px;
                        height: 32px;
                    }

                    > .GFXBottom {
                        left: 28px;
                        right: 28px;
                        background-image: url('/static/images/shelves-v1/2d/shelf-middle.png');
                        background-repeat: repeat-x;
                        background-size: 1px 32px;
                        z-index: 5;
                    }

                    > .GFXBottomLeft {
                        left: 6px;
                        width: 26px;
                        background-image: url('/static/images/shelves-v1/2d/shelf-left.png');
                        background-size: 26px 32px;
                        z-index: 10;
                    }

                    > .GFXBottomRight {
                        right: 6px;
                        width: 26px;
                        background-image: url('/static/images/shelves-v1/2d/shelf-right.png');
                        background-size: 26px 32px;
                        z-index: 10;
                    }
                }

                &.LastBodyArea {
                    z-index: 14;

                    &.emptyShelf {
                        z-index: 30;
                    }
                }
            }

            .CustomShelf {
                background: $white;
                box-shadow: 0 10px 14px -8px rgba(0, 0, 0, 0.41);
            }

            .ProductsShelfBase {
                background: transparent;
            }

            .SplitShelfAction {
                .BodyArea {
                    left: 0;
                    top: 21px;
                    height: 45px;
                    z-index: 30 !important;
                }
            }
        }
    }
}
