@import 'core';
@import './ShelfStyle2D';
@import './ShelfStyle3D';

.AppCore .CustomPageDesigner {
    .EditorCanvas {
        .Frame {
            .RTView {
                position: relative;
                z-index: 5;
                overflow: hidden;

                ul,
                ol {
                    padding: 0 0 0 1.5em;
                }

                strong {
                    font-weight: 600;
                }
            }

            .ProductFrame .RTView {
                overflow: visible;
            }

            .FrameResizer {
                z-index: initial;
            }

            .FrameResizer > * {
                z-index: 10;
            }

            .RTEditor {
                position: relative;
                z-index: 15;
                overflow: hidden;

                .HTMLEditor {
                    position: relative;
                    background: transparent;
                    border: none;

                    [class^='RichTextEditor__editor___'] {
                        background: transparent;
                        font-size: inherit;
                        height: 100%;
                    }

                    [class^='EditorToolbar__'] {
                        display: none;
                    }

                    [style*='font-weight: bold;'] {
                        font-weight: 600 !important;
                    }

                    .public-DraftEditor-content {
                        padding: 0;
                        overflow: hidden;
                    }

                    .DraftEditor-editorContainer {
                        border: none;
                    }
                }
            }

            &.image {
                .stretch-fit,
                .proportional-inside {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }
            }

            .EmptyFrame {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .Icon {
                    color: $grey-dark-2;
                }
            }

            .ShelfFrame {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            > video,
            .FrameImage {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }

            .FrameEyedropArea {
                position: absolute;
                z-index: 99;

                outline: 1px solid rgba(3, 94, 1, 0);
                box-shadow: 0 0 0 0 rgba(3, 94, 1, 0);

                transition:
                    box-shadow 0.2s,
                    outline 0.2s;

                &.FrameEyedropAreaJustAdded {
                    outline: 1px solid rgba(0, 0, 0, 0.57);
                    box-shadow: 0 0 7px 3px rgba(49, 131, 192, 0.3);
                }
            }
        }
    }
}
