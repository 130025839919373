@import 'core';

.AppCore {
    .VisionSpinner {
        display: flex;

        > button {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        > span {
            text-align: center;
            display: block;
            padding: 0 23px;
            line-height: 69px;
            font-size: 22px;
            min-width: 90px;
        }
    }
}
