:root {
    --primitive-sizes-0: 0px;
    --primitive-sizes-1: 1px;
    --primitive-sizes-2: 2px;
    --primitive-sizes-4: 4px;
    --primitive-sizes-8: 8px;
    --primitive-sizes-12: 12px;
    --primitive-sizes-16: 16px;
    --primitive-sizes-20: 20px;
    --primitive-sizes-24: 24px;
    --primitive-sizes-32: 32px;
    --primitive-sizes-40: 40px;
    --primitive-sizes-48: 48px;
    --primitive-sizes-56: 56px;
    --primitive-sizes-64: 64px;
    --primitive-sizes-72: 72px;
    --primitive-sizes-80: 80px;
    --primitive-sizes-96: 96px;
    --primitive-sizes-112: 112px;
    --primitive-sizes-128: 128px;
    --primitive-sizes-144: 144px;
    --primitive-sizes-160: 160px;
    --primitive-sizes-176: 176px;
    --primitive-sizes-192: 192px;
    --primitive-sizes-224: 224px;
    --primitive-sizes-256: 256px;
    --primitive-sizes-288: 288px;
    --primitive-sizes-320: 320px;
    --primitive-sizes-352: 352px;
    --primitive-sizes-384: 384px;
    --primitive-sizes-448: 448px;
    --primitive-sizes-512: 512px;
    --primitive-sizes-576: 576px;
    --primitive-sizes-640: 640px;
    --primitive-sizes-768: 768px;
    --primitive-sizes-896: 896px;
    --primitive-sizes-1024: 1024px;
    --primitive-sizes-1152: 1152px;
    --primitive-sizes-1280: 1280px;
    --primitive-sizes-1440: 1440px;
    --primitive-sizes-1600: 1600px;
    --corner-radius-no-radius: var(--primitive-sizes-0);
    --corner-radius-xs: var(--primitive-sizes-1);
    --corner-radius-sm: var(--primitive-sizes-2);
    --corner-radius-md: var(--primitive-sizes-4);
    --corner-radius-lg: var(--primitive-sizes-8);
    --corner-radius-xl: var(--primitive-sizes-16);
    --corner-radius-round: 50%;
    --primitive-sizes-14: 14px;
    --primitive-sizes-18: 18px;
    --primitive-sizes-28: 28px;
    --primitive-sizes-22: 22px;
    --corner-radius-2xl: var(--primitive-sizes-24);
    --icon-2xs: var(--primitive-sizes-12);
    --icon-xs: var(--primitive-sizes-14);
    --icon-sm: var(--primitive-sizes-16);
    --icon-md: var(--primitive-sizes-24);
    --icon-lg: var(--primitive-sizes-32);
    --icon-xl: var(--primitive-sizes-40);
    --icon-2xl: var(--primitive-sizes-48);
    --primitive-sizes-216: 216px;
    --font-family-base: 'Calibre', sans-serif;
}
