@import 'core';

.AppCore {
    .HiddenSlideViewerScreen {
        background: $black;
    }

    .HiddenSlideViewer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        .HiddenSlideViewerSlidesWrap {
            position: relative;
            height: 100%;
            width: 100%;
            overflow: hidden;
        }

        .SlideshowViewerSlide {
            width: 100%;
            height: 100%;
            background: $white;
        }

        .SlideshowViewerSlidePlaceholder {
            display: block;
        }

        .ProductWallDesigner > .Canvas,
        .CustomPageDesigner > .EditorCanvas {
            position: relative;
            transform-origin: top left;
        }
    }
}
